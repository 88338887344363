import React, { useLayoutEffect } from "react";
import * as classes from "./AlternatingLayout.module.scss";
import Img from "gatsby-image";

const AlternatingLayout = ({ items }) => {
  useLayoutEffect(() => {
    const containers = Array.from(
      document.getElementsByClassName("alternating-container")
    );
    containers.forEach(container => {
      const index = container.getAttribute("dataindex");

      // Add "read more" if the content overflows
      // Todo: add fading styled to text container
      if (checkOverflow(container)) {
        container.classList.add(classes.withFadeOut);

        const buttonForIndex = document.querySelector(
          `button[dataindex='${index}']`
        );
        buttonForIndex.classList.add(classes.active);
      }
    });
  }, []);

  const expandToggle = index => {
    const container = document.querySelector(
      `.${classes.ContentColumn}[dataindex='${index}']`
    );

    container.classList.toggle(classes.withFadeOut);
    container.classList.toggle(classes.expanded);
  };

  const rows = items?.map((item, index) =>
    item.fluid ? (
      <div className={classes.Row}>
        <div className={classes.Column}>
          <Img fluid={item.fluid} style={{ width: "100%" }} />
        </div>
        <div
          dataindex={index}
          className={`${classes.ContentColumn} alternating-container`}
        >
          <div
            dangerouslySetInnerHTML={{ __html: item.html }}
            className="alternating-content"
          />
          <button
            onClick={expandToggle.bind(null, index)}
            dataindex={index}
            className={classes.readMoreButton}
          ></button>
        </div>
      </div>
    ) : (
      <div className={classes.Row}>
        <div className={classes.ContentColumn}>
          <h1>{item.title}</h1>
        </div>
        <div
          className={`${classes.Column}`}
          dangerouslySetInnerHTML={{ __html: item.html }}
        />
      </div>
    )
  );

  return <div>{rows}</div>;
};

const checkOverflow = el => {
  const isOverflowing =
    el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;

  return isOverflowing;
};
export default AlternatingLayout;
